import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "International Product Returns",
  "description": "A complete guide on how to return products bought from a foreign country. What the legislations are, and hwo to complete the requirements. ",
  "author": "Ally Cheng",
  "categories": ["shipping-packaging"],
  "date": null,
  "featured": false,
  "tags": ["shipping-packaging"],
  "relatedArticles": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "definitions"
    }}>{`Definitions`}</h2>
    <p><strong parentName="p">{`1. Pure returns - returns`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Customs return`}</strong>{` generally refers to the management measures for the direct return of imported goods by the Customs Bureau.`}</li>
      <li parentName="ul"><strong parentName="li">{`General returned goods`}</strong>{` refer to the goods that have been declared for export or import, and are returned for import or export due to various reasons.`}</li>
      <li parentName="ul"><strong parentName="li">{`Direct returned goods`}</strong>{` means that the consignee or consignor of the imported goods, the person in charge of the original means of transport, or their agent (will be referred to as the parties) applies for direct return of the imported goods overseas after entering the country and before completing the customs clearance. This will be done according to the existing state regulations.`}</li>
    </ul>
    <p><strong parentName="p">{`2. Return for repair`}</strong></p>
    <p>{`It means that the goods exported to foreign countries are returned back to their country of origin to be repaired. This should occur during the warranty period and is often caused due to quality reasons. `}</p>
    <h2 {...{
      "id": "return-reasons"
    }}>{`Return reasons`}</h2>
    <p>{`A. After the outbound goods arrive at the destination country, they are rejected by foreign businessmen due to the reasons of the goods themselves, such as:`}</p>
    <p>{`1`}{`.`}{` Return due to packaging reasons;`}</p>
    <p>{`2`}{`.`}{` The return of the goods due to the need to upgrade the system of the goods itself;`}</p>
    <p>{`3`}{`.`}{` After the product leaves the factory, the parts fail and need to be replaced with new parts.`}</p>
    <p>{`B. Customs and other agencies refuse to clear customs because the relevant documents do not match the actual product. For example, due to the carelessness of the exporter, the wooden frame has no IPPC certification mark, so it cannot be cleared in the destination country.`}</p>
    <h2 {...{
      "id": "methods-of-return-and-repair"
    }}>{`Methods of Return and Repair`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Repair in the recipient's country.`}</strong><br parentName="p"></br>{`
`}{`To do so, a reliable engineer in the destination country should find out the reason for the repair. Afterward, the manufacturer in the country of origin will send in the required tools, materials, or their own engineer to repair the product.`}<br parentName="p"></br>{`
`}{`The process puts a relatively high cost on the logistics and labor costs, so it's not very much recommended for international purchases. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Ship to country of origin for repair.`}<br parentName="strong"></br>
          </strong>{`Exported goods can be returned to the factory within a certain period of time. It is necessary to provide the current customs declaration form and related return materials to apply to the export customs. If there is an export tax refund, the refunded tax needs to be returned temporarily. There is a time limit for shipping to the factory, and an equivalent deposit must be paid. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Choose Hong Kong as the repair location`}</strong></p>
        <p parentName="li">{`As a free trade port, Hong Kong is very developed in terms of logistics, but it is expensive in terms of maintenance costs such as labor costs and logistics costs. It can be judged according to specific circumstances such as the value of the goods, the maintenance process, and whether large-scale equipment is required. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Choose a bonded area for repair`}</strong></p>
        <p parentName="li">{`The bonded area is equivalent to the function of domestic and foreign customs, which means that the goods are still being repaired abroad, and the goods are returned to the bonded area for inspection and maintenance without applying to the customs for returning the goods, without paying a deposit, and without paying taxes. `}<strong parentName="p">{`Most factories currently do repairs with this method.`}</strong>{` `}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "return-process"
    }}>{`Return Process`}</h2>
    <ol>
      <li parentName="ol">{`Get the Bill of Lading (BoL) from the shipping company.`}</li>
      <li parentName="ol">{`Fill in for customs declaration.`}</li>
      <li parentName="ol">{`Declaring party will pay a deposit for return and repair to the customs`}<br parentName="li"></br>
        {`The deposit will be refunded within 6 months. If the process took a longer time, the refund will not be refunded. `}</li>
      <li parentName="ol">{`After customs release, pick up the goods from the dock or warehouse to back to the repairing party's warehouse or factory. `}</li>
      <li parentName="ol">{`The repair process should be done under 6 months' time, starting from when the import declaration was first received. During this time, the goods should be re-declared to customs. `}</li>
      <li parentName="ol">{`After the customs export declaration is released, the declaring party can apply for a refund of the security deposit.`}</li>
      <li parentName="ol">{`The party receives the security deposit refund from the Customs Bureau.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`Notes on import declaration for return and repair`}</p>
      <ol parentName="blockquote">
        <li parentName="ol">{`The period from export to return shall not exceed one year.`}</li>
        <li parentName="ol">{`There must be quality problems before they can be temporarily imported in the form of return for repair.`}</li>
        <li parentName="ol">{`According to the repair time of returning to the factory for half a year, if half a year is not enough for repair, you can apply to the customs for an extension of another half year, and you can apply for a maximum of 2 extensions. Within the repair period, you can apply for re-export at any time after the repair is completed.`}</li>
      </ol>
    </blockquote>
    <h2 {...{
      "id": "documents-required-for-returns"
    }}>{`Documents Required for Returns`}</h2>
    <ol>
      <li parentName="ol">{`In addition to the customs declaration, you'll also need the provide an inspection declaration, a wooden packaging statement, and also quality and safety guarantee.`}</li>
      <li parentName="ol">{`Original export declaration form `}{`[`}{`2 copies, stamped`}{`]`}{`, invoice, packing list `}{`[`}{`stamped`}{`]`}</li>
      <li parentName="ol">{`Statement explaining the current situation (comparison, with detailed reasons for repair) `}{`[`}{`2 copies, stamped`}{`]`}</li>
      <li parentName="ol">{`Import and export tax deposit application form `}{`[`}{`1 copy, stamped`}{`]`}</li>
      <li parentName="ol">{`Import and export tax payment guarantee notice `}{`[`}{`1 copy, stamped`}{`]`}</li>
      <li parentName="ol">{`Repair agreement `}{`[`}{`stamped and signed, copies in corresponding languages, e.g. English and Chinese, English and Italian, etc.`}{`]`}</li>
      <li parentName="ol">{`Correspondence mail (stating communication about repair agreement: reasons and confirmation) `}{`[`}{`stamp attached`}{`]`}</li>
      <li parentName="ol">{`Agreement on using a security deposit `}{`[`}{`stamped`}{`]`}</li>
      <li parentName="ol">{`The original official export contract (requirements on the status of the exported goods, and related instructions on maintenance matters), in line with the return repair instructions`}</li>
      <li parentName="ol">{`For any electronics, a statement should also be provided `}{`[`}{`stamped`}{`]`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      